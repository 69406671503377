import React from 'react'
import './about.css'
import myImage from '../../assets/my-image2.jpg'
import {GiGraduateCap} from 'react-icons/gi'
import {BsBookmarkStar} from 'react-icons/bs'
import {TfiWorld} from 'react-icons/tfi'

const About = () => {
  return (
    <section id='about'>
      
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

          <div className="about__me">
            <div className="about__me-image">
                <img src={myImage} alt="Me" />
            </div>
          </div>

          <div className="about__content">
            <div className="about__cards">

              <article className='about__card'>
                  <GiGraduateCap className='about__icon'/>
                  <h5>Degree</h5>
                  <small>Bsc. Computer Science <br /><i>University of Oklahoma.</i></small>
              </article>

              <article className='about__card'>
                  <BsBookmarkStar className='about__icon'/>
                  <h5>Current GPA</h5>
                  <small>3.93</small>
              </article>

              <article className='about__card'>
                  <TfiWorld className='about__icon'/>
                  <h5>Domains</h5>
                  <small>
                    <ul>
                      <li>Web Development</li>
                      <li>Embedded Programming</li>
                    </ul>
                  </small>
              </article>

              

            </div>
            <p>
                    I'm a former <b>Senior Software Engineer</b> currently taking a sabattical.
                    I am seeking a job in Web Development or in Embedded Programming to leverage my years of Professional Web Development knowlege and my hobby level Low Level Programming Skills.
            </p>

          </div>
      </div>
    </section>
  )
}

export default About